import { useState, useEffect, useContext } from 'react';
import { videoSocket } from './socket/video-socket';
import generateCreateReq from './utils/create';
import { getRequest, assignRequest } from './api/shorts-api';

import VideoInput from './components/VideoInput';
import ProcessingScreen from './components/ProcessingScreen';
import ShortsList from './components/ShortsList';
import TextEditor from './components/TextEditor';

import $ from "jquery";
import Modal from "react-bootstrap/Modal";
import SsemblePricing from './components/ShortsMaker/ShortsPricing/SsemblePricing';
import Loading from './components/Loading.js';
import { useDispatch } from "react-redux";
import { changeShortsMakerInputField } from './Redux/Actions/ToolActions.js'
import yt_icon_black from './assets/yt_icon_black.svg';
import BackIcon from './assets/back.svg';

import './App.css'

import ShortsMakerFieldContext from './ShortsMakerFieldContext';

import { dcsfindProgressByStringValue } from './utils/helpers.js';

const LoggedInUser = ({ userId, userEmail, ytUrl, requestId, onLoad, onCreate }) => {

  if (typeof userId !== 'undefined') {
    // console.log("User is logged in -- Check for request");
  }

  // console.log("--------userId------", userId);
  // console.log("--------userEmail------", userEmail);
  const ShortsMakerField = useContext(ShortsMakerFieldContext);
  // console.log("ShortsMakerField new UI", ShortsMakerField);
  const [url, setURL] = useState('');
  const [step, setStep] = useState(0);
  const [request, setRequest] = useState({});
  const [shortId, setShortId] = useState('');

  const [show, setShow] = useState(false);
  const [PopupShow, setPopupShow] = useState(false);
  const handleClose = () => setShow(false);
  const handlePopupClose = () => setPopupShow(false);

  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("Fetching Youtube Video...");

  const dispatch = useDispatch();


  const [start, setstart] = useState(0);
  const [end, setend] = useState(0);
  const [youTubeURL, setyouTubeURL] = useState('');



  useEffect(() => {
    GetPriceData();
    $(document).on("click", ".ss_gradient", function () {
      setShow(true);
    });
  }, []);

  const GetPriceData = () => {
    dispatch(
      changeShortsMakerInputField({
        priceTableData: ShortsMakerField?.shortcodeSettings1?.priceTableData,
      })
    );
  };


  useEffect(() => {
    // videoSocket.connect();
    // onLoad();
    if (requestId) {
      handleRequestClick(requestId);
    } else {
      setStep(1)
    }
  }, [onLoad, requestId]);

  const handleURLChange = (url) => {
    setURL(url);
  };

  const showProcessingScreen = async (requestId) => {
    // console.log('showProcessingScreen', requestId)
    const request = await getRequest(requestId);
    const videoStatus = request.status;
    setRequest(request);

    const progressItem = dcsfindProgressByStringValue(videoStatus);
    setProgress(progressItem.value);
    setMessage(progressItem.message);

    setStep(2);
  };

  const handleBack = () => {
    if (step > 2) {
      setStep(step - 1);
    }
  };

  const handleSuccess = (request) => {
    setRequest(request);
    if (step < 3) {
      setStep(step + 1);
    }
  };

  const handleShortsSelect = async (request, shortId) => {

    // console.log('handleShortsSelect', request)

    const selectedShort = request.shorts.find(({ id }) => id === shortId);
    const data = {
      file: request.file || request.file_low,
      totalDuration: request.end - request.start,
      ...selectedShort
    };

    // console.log('request data', request)
    // console.log('shorts data', data)

    const req = generateCreateReq(data);

    // console.log("-----req---", req);

    onCreate(req);

    setShortId(shortId)
    setStep(4)
  };

  const handleRequestClick = async (requestId) => {
    // console.log('Video clicked:', requestId);
    setStep(0);
    // Scroll to the top of the page
    window.scrollTo(0, 0);
    try {

      const request = await getRequest(requestId);

      if(request === undefined){
        alert("Request not found");
        setStep(1);
        return;
      }


      // console.log("After request status", request);

      const videoStatus = request.status;
      setRequest(request);

      const progressItem = dcsfindProgressByStringValue(videoStatus);
      setProgress(progressItem.value);
      setMessage(progressItem.message);

      const url = new URL(window.location);
      url.searchParams.set('requestId', requestId);
      window.history.pushState({}, '', url); // This will add the parameter without reloading the page

      if (videoStatus === 'task_complete') {
        setstart(new Date(request?.start * 1000).toISOString().slice(11, 19));
        setend(new Date(request?.end * 1000).toISOString().slice(11, 19));
        setyouTubeURL(request?.url);
        setStep(3);

      } else {
        setStep(2);
      }
    } catch (error) {
      console.error('Error fetching video status:', error);
    }
  };


  const onBackHandle = () => {
    window.location.href = ShortsMakerField?.shortcodeSettings1?.site_url;
  }


  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <div className="d-flex vh-100">
            <Loading />
          </div>
        );

      case 1:
        return (
          <>
            <VideoInput
              userId={userId}
              userEmail={userEmail}
              ytUrl={ytUrl}
              onURLChange={handleURLChange}
              onNextStep={showProcessingScreen}
              onClickVideo={handleRequestClick}
              ShortsMakerField={ShortsMakerField}
            // messengerService={messengerService} // Passing down the messengerService
            />
          </>
        );
      case 2:
        return (
          <ProcessingScreen
            request={request}
            onSuccess={handleSuccess}
            onBack={handleBack}
            previousProgress={progress}
            previousmessage={message}
            ShortsMakerField={ShortsMakerField}
          />
        );
      case 3:
        return (
          <>
            <ShortsList
              userId={userId}
              request={request}
              onSelect={handleShortsSelect}
              onBack={handleBack}
              ShortsMakerField={ShortsMakerField}
            />
          </>
        );
      case 4:
        return (
          <TextEditor
            request={request}
            shortId={shortId}
            onBack={handleBack}
          />
        );
      default:
        return (
          <VideoInput
            userId={userId}
            userEmail={userEmail}
            ytUrl={ytUrl}
            onURLChange={handleURLChange}
            onNextStep={showProcessingScreen}
            onClickVideo={handleRequestClick}
          // messengerService={messengerService} // Passing down the messengerService

          />
        );
    }
  };

  return (
    <div className="loginGenerateShortsWrapper">
      <div className="loginGeneratorShortsContainer">
        <div className="loginGeneratorShortsInner">
          <div className="formShortcode">
            <div className="shortsFormWrappr">
              {renderStep()}
            </div>
          </div>
        </div>
      </div>

      {ShortsMakerField?.UserStatusData &&
        !ShortsMakerField?.UserStatusData?.isProUser && (
          <>
            <Modal className="ss_modal" show={show} onHide={handleClose}>
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <div className="ModalWrapper">
                  <div className="leftContent">
                    <div className="shortsClipsWrapper">
                      <img
                        src="https://staging.ssemble.com/wp-content/uploads/2023/11/ProIcon-150x150.png"
                        alt=""
                      />
                      <h4 className="shortsClipHeading">
                        Create more Shorts Clips
                      </h4>
                      <p className="shortsClipContent">
                        Get 300 upload minutes to create more Shorts Clips.
                      </p>
                    </div>
                  </div>
                  <div className="rightContent">
                    <SsemblePricing ShortsMakerField={ShortsMakerField} />
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </>
        )}
    </div>
  )
}

export default LoggedInUser
