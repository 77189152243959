import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeShortsMakerInputField } from '../../Redux/Actions/ToolActions.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import uuid from 'react-uuid';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NonLogedInView from './TemplateParts/NonLoggedIn/NonLogedInView.js';
import LoggedInUser from '../.../../../LoggedInUser.js';
import Header from './../../components/Header.js';
const id = uuid();
function ShortsMaker({ shortcodeSettings }) {
	const ToolState = useSelector((state) => state.ToolReducer);
	const dispatch = useDispatch();
	const { ShortsMakerField } = ToolState;

	const [requestId, setrequestId] = useState('');


	useEffect(() => {
		// console.log("inside change eventeeeeeeeeeeeeeeeeeeeeee")
		const urlParams = new URLSearchParams(window.location.search);
		const requestIdParam = urlParams.get("requestId");
		if (requestIdParam !== undefined && requestIdParam !== null) {
		  setrequestId(requestIdParam);
		}
	
	  }, []);
	
	useEffect(() => {
		dispatch(
			changeShortsMakerInputField({
				shortcodeSettings1: shortcodeSettings,
			})
		);


	}, [ShortsMakerField.final_data]);
	useEffect(() => {

		dispatch(changeShortsMakerInputField({ id: id }));
	}, []);

	return (
		<>

			<Header />

			<LoggedInUser 
			userId='xIc_jIIwGEwcor70k9IBE'
			userEmail='deepak.anand@visions.net.in'
			ytUrl=''
			requestId={requestId}
			onLoad=''
			onCreate=''
			
			ShortsMakerField={ShortsMakerField} />

			{/* <NonLogedInView ShortsMakerField={ShortsMakerField} /> */}

			<Container>
				<Row className="justify-content-md-center p-5">
					<Col md="auto">
						<div className="custom-text">© Copyright 2024. All Rights Reserved. </div>
					</Col>
				</Row>
			</Container>

		</>
	);
}
export default ShortsMaker;
