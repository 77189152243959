import Short from './Short';
import BackIcon from '../../assets/back.svg';
import yt_icon_black from '../../assets/yt_icon_black.svg';
const frontendURL = process.env.REACT_APP_URL;
const ShortsList = ({ userId, request, onBack, onSelect, ShortsMakerField }) => {
  // console.log("request", request);


  // console.log("ShortsMakerField on listing:::: ", ShortsMakerField);

  const requestId = request?._id;
  const requestUserEmail = request?.userEmail;

  // const [start, setstart] = useState(0);
  // const [end, setend] = useState(0);
  // const [youTubeURL, setyouTubeURL] = useState('');

  const startFormatted = new Date(request?.start * 1000).toISOString().slice(11, 19);
  const endFormatted = new Date(request?.end * 1000).toISOString().slice(11, 19);
  const totalTime = request?.end - request?.start;
  const totalTimeFormated = new Date(totalTime * 1000).toISOString().slice(11, 19);
  const youTubeURL = request?.url;

  const onBackHandle = () => {
    // window.location.reload();
    window.location.href = frontendURL;
  }

  const handleClick = (shortsId) => {
    if (typeof onSelect === 'function') {
      onSelect(request, shortsId);
    }
  };


  const groupedByChapter = request.shorts.reduce((acc, item) => {
    const { chapterTitle } = item;
    const groupKey = chapterTitle || 'No Chapter Title';
    if (!acc[groupKey]) {
      acc[groupKey] = [];
    }
    acc[groupKey].push(item);
    return acc;
  }, {});


  return (
    <>
      <div className='shortsListTopNavWrap'>
        <div className='shortsListTopNav'>
          <button
            className="ss_back_btn"
            onClick={onBackHandle}
            style={{ paddingTop: '20px' }} >
            <img src={BackIcon} alt="back icon" className="inline" />
            <span className="text-xs text-grey-8">Back</span>
          </button>

          <a href={youTubeURL} target='_blank' className='mainTitleWrap'>
            <img src={yt_icon_black} alt='YoutTube Icon' />
            <p className="timingWraper">
              <span className='totalTime'>{totalTimeFormated}&nbsp;</span> (
              <span className='startTime'>&nbsp;{startFormatted}&nbsp;</span> -
              <span className='endTime'>&nbsp;{endFormatted}&nbsp;</span> )
            </p>
            <h3>{request?.title}</h3>
          </a>
        </div>
      </div>

      <div className='shortsMakerSmallWrap'>
        {/* New code as per chapters */}
        <div>
          <div className="short-item" >
            <Short userId={userId} requestUserEmail={requestUserEmail} item={ request.shorts[0]} index="0" ShortsMakerField={ShortsMakerField} requestId={requestId} />
          </div>
        </div>
      </div>
    </>
  )
};

export default ShortsList;
